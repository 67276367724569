import React from "react"
// import { css } from "@emotion/react"

const Header = ({ siteTitle }) => (
  <header>
    <div className="main-header">
      <div className="logo logo-color"></div>
      <h1 className="title">is a product designer.</h1>
    </div>
  </header>
)

export default Header
