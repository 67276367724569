import React, { useEffect, useState } from "react"
import { Global, css } from "@emotion/react"

export default function Tracky() {
  const virtualScreen = 480;
  const [width, setWidth] = useState(typeof window !== "undefined" ? window.innerWidth : virtualScreen);
  const [height, setHeight] = useState(typeof window !== "undefined" ? window.innerHeight : virtualScreen)
  const updateDimensions = () => {
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
  }

  useEffect(() => {
    window.addEventListener("resize", updateDimensions)
    return () => window.removeEventListener("resize", updateDimensions)
  }, [])

  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 })

  const onMouseMove = event => {
    const { pageX: x, pageY: y } = event
    setMousePosition({ x, y })
  }

  const { x, y } = mousePosition
  const hueU = Math.floor((y / height) * 180 * 1 ) + 230 // 15 sunrise
  const hueV = Math.floor((x / width) * 180 * 1 ) + 300 // 300 sunrise
  const lightU = 77.5 // + Math.floor( ( y / height ) * 5 )
  const lightV = 77.5

  useEffect(() => {
    document.addEventListener("mousemove", onMouseMove)
    // document.title = `x = ${x}`

    return () => {
      document.removeEventListener("mousemove", onMouseMove)
    }
  })

  return (
    <>
      <Global
        styles={css`
          .logo-color {
            background: linear-gradient(
              135deg,
              hsl(${hueU}, 100%, ${lightU}%) -5%,
              hsl(${hueV}, 100%, ${lightV}%) 100%
            );
          }

          .lead-color {
            background: linear-gradient(
                135deg,
                hsl(${hueU}, 100%, ${lightU}%) -200%,
                hsl(${hueV}, 100%, ${lightV}%) 200%
              );

              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;

          }

          .main-text {
            a::before {
            background: linear-gradient(
              135deg,
              hsl(${hueU}, 100%, ${lightU}%) -150%,
              hsl(${hueV}, 100%, ${lightV}%) 150%
            );
          }
        }
        `}
      />
    </>
  )
}
